.betting-container button {
    background-color: var(--secondary-color);
    color: var(--main-compliment-color);
    padding: 1rem;
    text-align: center;
    border: none;
    box-shadow: 0.2rem 0.3rem var(--main-accent-color);
}

.betting-container button:active {
    position: relative;
    top: 2px;
    box-shadow: 0.1rem 0.2rem var(--main-accent-color);
}

.betting-container button:hover {
    cursor: pointer;
    position: relative;
    top: 0.1rem;
}

.active-player button {
    color: white;
}

.non-active-player button {
    color: var(--secondary-color);
    background-color: var(--main-color);
    pointer-events: none;
}
