.card-back {
    border-radius: 10px;
    width: 16rem;
    height: 22rem;
    background-color: var(--secondary-color);
    border: 2px solid var(--main-accent-color);
    margin: 0 1rem;
    box-shadow: 3px 4px var(--main-accent-color);
    font-size: 25px;
}

.card-back:hover {
    position: relative;
    bottom: 1rem;
    width: 16.2rem;
    height: 22.2rem;
    box-shadow: 10px 12px var(--main-accent-color);
}