.player {
    background-color: var(--main-compliment-color);
    box-shadow: 2px 3px var(--main-accent-color);
    color: black;
    margin: 1rem 0.2rem 0.5rem 0rem;
    padding: 1rem;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
}

.player:hover {
    background-color: var(--secondary-color);
    cursor: pointer;
    color: white;
}

.player > .image-wrapper {
    height: 4rem;
    width: 4rem;
    overflow: hidden;
    border-radius: 50%;
    border: 3px solid var(--main-accent-color);
}

img {
    margin: 0 auto;
    height: 100%;
    width: auto;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.player-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 20rem;
}

.player-container p {
    margin: 0.1rem 0.5rem;
}

.active::after {
    font-size: 1.6rem;
    content: '\21A7';
    position: absolute;
}
