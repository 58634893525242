.card-face {
    border-radius: 10px;
    width: 16rem;
    height: 22rem;
    background-color: var(--main-compliment-color);
    border: 2px solid var(--main-accent-color);
    margin: 0 1rem;
    box-shadow: 3px 4px var(--main-accent-color);
    font-size: 25px;
}

.card-face:hover {
    position: relative;
    bottom: 0.2rem;
    right: 0.1rem;
    box-shadow: 4px 6px var(--main-accent-color);
}

.card-face .number {
    text-align: center;
    font-size: 70px;
    font-weight: 700;
}

.card-face .top-left::after {
    position: relative;
    top: 0.3em;
    left: 1em;
}

.card-face .top-right::after {
    position: relative;
    top: -1em;
    left: 8.5em;
}

.card-face .bottom-left::after {
    position: relative;
    top: 9.35em;
    left: 1em;
}

.card-face .bottom-right::after {
    position: relative;
    top: 8.05em;
    left: 8.4em;
}

.spades::after {
    content: '\2660';
}

.diamonds::after {
    content: '\2666';
    color: red;
}

.hearts::after {
    content: '\2665';
    color: red;
}

.clubs::after {
    content: '\2663';
}
