@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Noto+Serif+Display&family=Oswald:wght@200;300&family=Roboto&family=Roboto+Mono:wght@200&family=Square+Peg&display=swap');

body {
  font-family: 'Roboto Mono';
  font-weight: 700;
  overflow: hidden;
}

:root {
  --main-color: #131112;
  --secondary-color: #60656F;
  --main-accent-color: #279AF1;
  --secondary-accent-color: #C49991;
  --main-compliment-color: #F7F7FF;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lobby-configuration {
  position: absolute;
  right: 0;
  top: 1rem;
}

.auth {
  position: absolute;
  left: 0;
  top: 1rem;
}

.players-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.betting-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards-container {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
}

h1 {
  text-align: center;
  margin-bottom: 3rem;
}

button:hover {
  cursor: pointer;
  position: relative;
  top: 0.1rem;
}
