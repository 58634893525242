.lobby-config button {
    background-color: var(--secondary-color);
    color: var(--main-compliment-color);
    padding: 1.2rem;
    text-align: center;
    border: none;
    box-shadow: 0.2rem 0.3rem var(--main-accent-color);
}

.lobby-config button:active {
    position: relative;
    top: 2px;
    box-shadow: 0.1rem 0.2rem var(--main-accent-color);
}

.lobby-config {
    text-align: right;
}

input {
    padding: 0.2rem;
    border: 2px solid var(--main-accent-color);
    border-radius: 0.3rem;
}
